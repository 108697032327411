@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body,html{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

.chart-container{
    height: 300px;
    margin-bottom: 50px;

    &.large{
        height: 450px;
    }

    &.huge{
        height: 750px;
    }
}
.chart-group-title{
    margin-bottom: 30px;
    font-size: 32px;
}
.chart-title{
    font-size: 17px;
    margin-bottom: 30px;
    font-weight: bold;
}

.outdated-value{
    opacity: .2;
}

.score-card-container{
    transition: 0.1s ease-in-out;
    border-left:5px solid #0072f0;
    padding: 15px;
    margin-bottom: 30px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    .score-card-title{
        font-size: 20px;
    }
    .score-card-percent-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .pie-progress-bar-container{

    }
    .score-card-value{
        font-size: 70px;
        font-weight: 900;
        .currency-label{
            color: rgb(128, 128, 128);
            font-size: 20px;
        }
        & + .score-card-value{
            font-size: 40px;
        }
        &.small-font{

        }
    }
    &.estimated-card{
        color: #008000;
        border-left-color:  #008000;
        .currency-label{
            color: #008000;
            opacity: .5;
        }
        .suspicious_rev{
            color: #0BA43B;
        }
    }
    &.potential-card{
        color: #FFA500;
        border-left-color:  #FFA500;
        .currency-label{
            color: #FFA500;
            opacity: .5;
        }
    }
}


.react-datepicker-wrapper{
    display: block;
}

.info-box{
    background: #FFEB3B;
    display: inline-block;
    border-radius: 100%;
    line-height: 20px;
    width: 20px;
    text-align: center;
    margin-left: 10px;
    font-size: 12px;
    position: relative;
    top: -4px;
}


.showtime-table{
td,th{
    font-size: 12px;
    vertical-align: middle;
    padding: 5px 5px;
    a{
        color: #0072f0;
    }
}
    th{
        vertical-align: bottom;
    }
}

.recharts-default-tooltip, .custom-tooltip { 
    overflow-y: auto; 
    z-index: 999999999;
    .recharts-tooltip-item-list { 
        max-height: 50vh; 
    }
}

.custom-tooltip {
    margin: 0px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    white-space: nowrap;
}


:root {
    --scrollbar-track-color: transparent;
    --scrollbar-color: rgba(0,0,0,.2);
  
    --scrollbar-size: .375rem;
    --scrollbar-minlength: 1.5rem;
}
.recharts-default-tooltip::-webkit-scrollbar {
    height: var(--scrollbar-size);
    width: var(--scrollbar-size);
}
.recharts-default-tooltip::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
}
.recharts-default-tooltip::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
}
.recharts-default-tooltip::-webkit-scrollbar-thumb:vertical {
    min-height: var(--scrollbar-minlength);
}
.recharts-default-tooltip::-webkit-scrollbar-thumb:horizontal {
    min-width: var(--scrollbar-minlength);
}

.recharts-legend-wrapper{
    text-align: center;
}

.compare-chart-range{
    height: 38px;
}



.heatmap-custom-legend {
    border-top-left-radius: 10px;
    position: absolute;
    width: 100px;
    height: 260px;
    background-color: rgba(255, 255, 255, 0.8);
    bottom: 0;
    right: 0;
    z-index: 1000;
    display: grid;
    grid-template-columns: 1fr;
    padding: 1% 1% 1.6% 1%;

    .progress {
        border-radius: 3px;
        box-shadow:none;
        position: relative;
        width: 20px;
        height: 100%;
        display: inline-block;
        margin-right: 10px;

        .progress-bar {
            width: 100%!important;
            position: absolute;
            bottom: 0;
            background: linear-gradient(to bottom, 
            #fb322e 0%, 
            #fb332d 10%, 
            #ee7b2d 20%, 
            #edca15 30%, 
            #c8f514 40%, 
            #a1ed1c 50%, 
            #28eb17 60%, 
            #42ed47 70%, 
            #34d163 80%, 
            #6fa6cd 90%, 
            #8db0e4 100%);
            height: 100%;
        }
    }
}
